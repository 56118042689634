<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="jxRegulationManagementList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @add-change="editDetail()"
      @import-click="isImport = true"
    >
      <template slot="status" slot-scope="{ item }">
        <el-tag type="success" v-if="item.status">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="editDetail(item)"
          type="text"
          size="small"
          >编辑</el-button
        >
        <el-button
          class="polecy-button"
          @click.native.prevent="handleDel(item)"
          type="text"
          size="small"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <AddRegulationDialog
      v-model="isAddVisible"
      :editDetail="editData"
      @changeList="changeGetList"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import AddRegulationDialog from "@/views/routerPerformanceManagement/components/regulationManagementList/components/AddRegulationDialog.vue";
import {
  performanceRulePage,
  performanceRemoveByIds,
  exportPerformanceRule, performancePlanPage, dictionaryBatch
} from "@/api/policy";
import {
  pageProjectList,
  removeBatchById,
  listApplyDownload,
} from "@/api/project";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import dayjs from "dayjs";
export default {
  name: "ProjectManagementList",
  components: { GeneralTable, AddRegulationDialog },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isAdd: true,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        isSelection: false,
        disFuzzySearch: true,
        pageSize: 10,
        searchList: [
          {
            label: "规则名称",
            prop: "ruleName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          // {
          //   label: "险种类型",
          //   prop: "projectType1",
          //   formType: "select",
          //   filterable: true,
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   selectList: [
          //     { value: "", text: "全部" },
          //     { value: 1, text: "工程机械" },
          //     { value: 2, text: "车险" },
          //     { value: 3, text: "非车险" },
          //   ],
          //   // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          // },
          {
            label: "创建时间",
            prop: "createTime",
            format: "yyyy.MM.dd ",
            formType: "daterange",
            clearable: true,
            prop: ["createStartDateTime", "createEndDateTime"],
          },
          {
            label: "更新时间",
            prop: "updateDateTime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true, 
            prop: ["updateStartDateTime", "updateEndDateTime"],
          },
          {
            label: "客户名称",
            prop: "customerName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "方案名称",
            prop: "programmeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "状态",
            prop: "status",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: false, text: "禁用" },
              { value: true, text: "启用" },
            ],
            clearable: true,
            placeholder: "请选择",
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "规则名称",
          prop: "ruleName",
          isShow: true,
        },
        {
          label: "客户名称",
          prop: "customerName",
          isShow: true,
        },
        {
          label: "客户证件号",
          prop: "customerIdCar",
          isShow: true,
        },
        {
          label: "方案名称",
          prop: "programmeName",
          isShow: true,
        },
        {
          label: "代理商返点",
          prop: "agentKick",
          isShow: true,
        },
        {
          label: "佣金绩效费率",
          prop: "brokerage",
          isShow: true,
        },
        {
          label: "其他绩效费率",
          prop: "otherBrokerage",
          isShow: true,
        },
        {
          label: "状态",
          prop: "status",
          isShow: true,
          isSlot: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 180,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: null,
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
      },
    };
  },
  created() {
    // dictionaryBatch({ codes: "projectItems" }).then((res) => {
    //   this.option.searchList.find((item) => {
    //     if (item.prop === "projectType") {
    //       item.selectList = res.data.projectItems.map((code) => {
    //         return {
    //           text: code.dictValue,
    //           value: code.dictValue,
    //         };
    //       });
    //     }
    //   });
    //   //console.log(res);
    // });
    // performancePlanPage({
    //   pageIndex: 1,
    //   pageSize: 9999
    // }).then((res) => {
    //   this.option.searchList.find((item) => {
    //     if (item.prop === "programmeName") {
    //       console.log(res.data);
    //       // item.selectList = res.data.records.map((code) => {
    //       //   return {
    //       //     text: code.dictValue,
    //       //     value: code.dictValue,
    //       //   };
    //       // });
    //     }
    //   });
    //   //console.log(res);
    // });
  },
  computed: {},
  methods: {
    getList(v, pageNum, pageSize, n, type) {
      if (v.createStartDateTime) {
        v.createStartDateTime =
          dayjs(v.createStartDateTime).format("YYYY-MM-DD") + " 00:00:00";
        v.createEndDateTime =
          dayjs(v.createEndDateTime).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (v.updateStartDateTime) {
        v.updateStartDateTime =
          dayjs(v.updateStartDateTime).format("YYYY-MM-DD") + " 00:00:00";
        v.updateEndDateTime = dayjs(v.updateEndDateTime).format("YYYY-MM-DD") + " 23:59:59";
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString() 
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        {
          ...v,
          pageIndex: pageNum,
          pageSize: pageSize,
          fuzzyLookupField: v.keyword,
        }
      );
      performanceRulePage(this.oldSearchData).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("详情");
      this.$router.push({
        path: "/routerProjectManagement/projectDetails?id=" + e.id,
      });
    },
    exportData(e) {
      exportPerformanceRule({
        ...this.oldSearchData,
      });
    },
    handleDel(e) {
      this.$confirm("确认删除?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        performanceRemoveByIds([e.id]).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      this.isAddVisible = true;
      if (e && e.id) {
        this.editData = e;
      }else {
        this.editData = null;
      }
      // this.$router.push({
      //   path: `/routerProjectManagement/addProject${e.id ? "?id=" + e.id : ""}`,
      // });
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({
        dictType: this.machineryProductTypeList.find(
          (val) => val.dictName == this.ruleForm.deviceType
        ).dictType,
      })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = "";
      this.getMachineryEquipmentTypeList();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
